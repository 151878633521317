
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import FilterCashbookItem from "@/assets/models/FilterCashbookItem";
import CashbookPaymentMethodResponseEntry
    from "@/assets/service/responseTypes/cashbook/cashbookPaymentMethods/CashbookPaymentMethodResponseEntry";
import i18n from "@/i18n";

@Component({
    components: {},
})
export default class FilterMenu extends Vue {
    @Prop({required: true})
    private filter!: FilterCashbookItem;
    @Prop({})
    private allowedDates: string[] | undefined;
    private open: boolean = false;
    private filterCopy: FilterCashbookItem = new FilterCashbookItem();
    private allCashbookPaymentMethod: CashbookPaymentMethodResponseEntry =
        new CashbookPaymentMethodResponseEntry(-1, 0, i18n.t('All').toLocaleString());
    private dateMenu: boolean = false;

    private checkAllowed(val: string) {
        if (this.allowedDates) {
            return this.allowedDates.find((entry) => {
                return val === entry
            }) !== undefined;
        }
        return true;
    }

    @Watch("open")
    openOnChange() {
        if (this.open) {
            this.filterCopy = JSON.parse(JSON.stringify(this.filter));
        }
    }

    private get payments() {
        return [this.allCashbookPaymentMethod]
            .concat(this.$store.getters.availableCashbookPayments as
                CashbookPaymentMethodResponseEntry[]);
    }

    public get dateRange(): string[] {
        const date = new Date(this.$store.getters.selectedYear + "-" + (this.$store.getters.selectedMonth + 1) + "-01");
        const firstDay = new Date(date.getFullYear(), date.getMonth() , 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() +1, 0);
        if (this.filterCopy.DATUM) {
            const filterDate = new Date(new Date(this.filterCopy.DATUM).toDateString());
            console.log(filterDate)
            if (!(filterDate >= firstDay && filterDate <= lastDay)) {
                this.filterCopy.DATUM = this.filter.DATUM = null;
            }
        }
        return [this.getDateString(firstDay), this.getDateString(lastDay)];
    }

    private get formattedDate() {
        if (this.filterCopy.DATUM) {
            return this.$d(new Date(this.filterCopy.DATUM), "date");
        }
        return "";
    }

    private set formattedDate(value: string) {
        this.filterCopy.DATUM = null;
    }

    private save() {
        this.filter.BUCHUNGSTEXT = this.filterCopy.BUCHUNGSTEXT;
        this.filter.DATUM = this.filterCopy.DATUM;
        this.filter.PAYMENT = this.filterCopy.PAYMENT;
        this.open = false;
    }

    private clear() {
        this.filter.clear();
        this.open = false;
    }

    public getDateString(date: Date): string {
        return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
    }

    private clearSearch() {
        this.filterCopy.DATUM = null;
    }
}
