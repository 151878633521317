import Vue from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';
import VueCurrencyInput from 'vue-currency-input';

import App from '@/App.vue';
import './registerServiceWorker';
import router from '@/router';
import store from '@/store/store';
import vuetify from '@/plugins/vuetify';
import ports from '@/plugins/ports';
import i18n from '@/i18n';


Vue.use(VueCurrencyInput);
Vue.use(VueObserveVisibility);
Vue.use(ports, store);

export const globalEventBus = new Vue();
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app');
