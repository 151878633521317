
import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import Sidemenu from '@/components/general/sidemenu/Sidemenu.vue';
import AproconFooter from '@aprocon-software/aprocon-footer';
import AproconHeader from '@aprocon-software/aprocon-header';
import GenericErrorHandler from '@/components/cashbook/dialogs/error/GenericErrorHandler.vue';
import Webservice from '@/assets/service/Webservice';
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import PersistentDialog from "@/components/general/dialogs/PersistentDialog.vue";
import store from "@/store/store";
import router from "@/router";
import {globalEventBus} from "@/main";

@Component({
    components: {
        PersistentDialog,
        LoadingSpinner,
        AproconHeader,
        GenericErrorHandler,
        AproconFooter,
        Sidemenu,
    },
})
export default class App extends Vue {
    @Watch("$route")
    private async routeChanged(): Promise<void> {
        if (!this.initialized && this.$route.meta?.loginRequired) {
            await this.initialize();
        }
    }

    @Ref()
    private messageDialog!: PersistentDialog;
    @Ref('menu')
    private readonly menu?: Sidemenu;

    @Ref('header')
    private readonly header?: any;

    private initialized = false;


    private keepAlive(): void {
        this.$store.dispatch('keepAlive')
            .then(async (success: boolean) => {
                if (success) {
                    // test credentials every 60 seconds
                    setTimeout(this.keepAlive, 1000 * 60);
                } else {
                    if (this.$store.getters.caller) {
                        const redirectUrl = this.$store.getters.caller;
                        this.$store.commit("SET_CALLER", "");
                        window.location.href = redirectUrl;
                    } else {
                        await this.$store.dispatch('login');
                    }
                }
            })
            .catch((e) => {
                this.$store.dispatch('login');
            });
    }

    private async initialize() {
        try {

            await this.$store.dispatch('login');
            // initialize legacy backend
            await this.$nextTick(async () => {
                // set branch
                // store.commit('SET_SELECTED_CASHBOOK', cashbooks[0] || {});


                let selectedBranch = Number.parseInt(this.$store.getters["branchId"]);

                this.$store.commit("setBranchId", selectedBranch);
                // set selected cashbook by table filiale - cashbook
                const cashbooks = await Webservice.getCashbooks();
                if (cashbooks && cashbooks.length > 0) {
                    const book = cashbooks.find((book) => {
                        if (book.FILIALID) {
                            return book.FILIALID === selectedBranch;
                        }
                    });
                    if (book && book.NUM) {
                        this.$store.commit('SET_SELECTED_CASHBOOK', book);
                    } else {
                        console.log(`No Cashbook matches FilialId = ${selectedBranch}`);
                        this.$store.commit('SET_SELECTED_CASHBOOK', cashbooks[0]);
                    }
                } else {
                    await this.messageDialog.showConfirmation("Kassenbuch", "Diesem Benutzer wurden keine Kassenbücher zugeordnet.\r\nMöchten Sie jetzt abgemeldet werden?", () => {
                        if (this.$store.getters.caller) {
                            this.$store.commit("SET_CALLER", "");
                        } else {
                            this.$ports.auth.logout();
                        }
                    });

                }

            });
            await Webservice.init();
            this.keepAlive();


            // get isa from query
            const query = {...this.$route.query};
            if (query.caller) {
                const caller = query.caller;
                this.$store.commit('SET_CALLER', caller);
                delete query.caller;
                await this.$router.push(query);
            }


        } catch (e: any) {
            if (e.message) {
                this.messageDialog.showError("Init", e.message);
            } else {
                this.messageDialog.showError("Init", e);
            }

        } finally {
            this.initialized = true;
        }

    }

    private showServiceError(serviceError:any) {
        this.messageDialog.showError("Es ist ein Fehler aufgetreten. (" + serviceError.ERRORNUMBER + ")",serviceError.ERRORDESCRIPTION);
    }
    @Watch('$route')
    private closeMobileSideMenu() {
        this.header?.close();
    }

    private closeAllMenus() {
        this.menu?.closeCombobox();
    }

    private get title(): string {
        const cashbookTitle = this.$store.getters.selectedCashbook?.VAL || '';
        if (this.$route.meta?.headerHasBranchInfo && this.$route.meta?.headerTitle) {
            return this.$route.meta.headerTitle + " - " + cashbookTitle;
        }
        return cashbookTitle;
    }

    async mounted() {
        globalEventBus.$on("showServiceError", this.showServiceError);
        if (!this.$store.getters["serviceUrl"]) {
            if (this.$router.currentRoute.path.toLowerCase() === "/start" || this.$router.currentRoute.path.toLowerCase() === "/closeday" ) {

                await this.$router.replace("/");
            } else {
                await this.initialize();
            }
        }else {
            if (this.$router.currentRoute.path.toLowerCase() !== "/start" && this.$router.currentRoute.path.toLowerCase() !== "/closeday") {
               await this.initialize();
            }
        }
    }
    unmounted() {
        globalEventBus.$off("showServiceError", this.showServiceError);
    }
}
