
  import { Component, Watch } from 'vue-property-decorator';
  import Cashbook from '@/views/cashbook/Cashbook';
  import CashbookEntriesResponseEntry
    from '@/assets/service/responseTypes/cashbook/cashbookEntries/CashbookEntriesResponseEntry';
  import DialogMode from '@/components/cashbook/dialogs/transactionDialog/DialogMode';
  import isIngestion from '@/assets/utils/CashbookUtils';
  import PermissionUtil from "@/assets/utils/PermissionUtil";
  import TransactionDialog
    from "@/components/cashbook/dialogs/transactionDialog/TransactionDialog.vue";
  import DayEndClosingDialog
    from "@/components/cashbook/dialogs/dayClosing/DayEndClosingDialog.vue";
  import MobileDayPicker
    from "@/components/general/genericComponents/dateDrivenComponents/MobileDayPicker.vue";
  import Lightbox from "@/components/cashbook/dialogs/lightbox/Lightbox.vue";
  import moment from "moment-mini";
  import { REQUEST_DATEFORMAT } from "@/assets/utils/DateUtil";
  import Webservice from '@/assets/service/Webservice';
  import CashbookPaymentMethodResponseEntry
      from "@/assets/service/responseTypes/cashbook/cashbookPaymentMethods/CashbookPaymentMethodResponseEntry";
  import i18n from "@/i18n";
  import { CashbookEntryAttachment } from '@/modules/cashbook';
  import {globalEventBus} from "@/main";

  @Component({
    components: {
      MobileDayPicker,
      DayEndClosingDialog,
      TransactionDialog,
      Lightbox
    },
  })
  export default class CashbookMobile extends Cashbook {
    showTakePreStockStored: boolean = false;

    showDayEndClosingStored: boolean = false;

    isEmptyCashbook:boolean=false;

    loadReportForDay: number = -1;

    imageURL: string = '';

    private attachment: CashbookEntryAttachment|null = null;

    private allCashbookPaymentMethod: CashbookPaymentMethodResponseEntry =
          new CashbookPaymentMethodResponseEntry(-1, 0, i18n.t('All').toLocaleString());
    private get payments() {
          return [this.allCashbookPaymentMethod]
              .concat(this.$store.getters.availableCashbookPayments as
                  CashbookPaymentMethodResponseEntry[]);
    }
    get selectedDay(): number {
      if (Number(this.$store.getters.selectedYear) !== this.selectedYear
        || Number(this.$store.getters.selectedMonth) !== this.selectedMonth) {
        this.selectedMonth = this.$store.getters.selectedMonth;
        this.changeMonth();
      }
      return Number(this.$store.getters.selectedDay);
    }

    set selectedDay(d: number) {
      this.updateOpenEntries();
      this.$store.commit('SET_SELECTED_DAY', d);
    }

    get isTodaySelected(): boolean {
      return this.now.getFullYear() === Number(this.selectedYear)
        && this.now.getMonth() === Number(this.selectedMonth)
        && this.now.getDate() === Number(this.selectedDay);
    }

    get calendarIcon(): string {
      return require('@/assets/icons_exclamation_calendar.svg');
    }

    get showTakePreStockBtn(): boolean {
      const date = this.getDate(this.selectedDay);
      return date.getDate() === this.now.getDate()
        && date.getMonth() === this.now.getMonth()
        && date.getFullYear() === this.now.getFullYear()
        && this.showTakePreStockStored;
    }

    get showDayEndClosingBtn(): boolean {
      const date = this.getDate(this.selectedDay);
      return date.getDate() === this.now.getDate()
        && date.getMonth() === this.now.getMonth()
        && date.getFullYear() === this.now.getFullYear()
        && PermissionUtil.hasPermissionForClosure()
        && this.getEntriesForDay(this.selectedDay).length > 0
        && this.$store.getters.cashbookBillingState.AbrechnungStatus === 0
        && this.showDayEndClosingStored;
    }

    get permissionToEdit(): boolean {
      return PermissionUtil.hasPermissionToEditCashbookEntry();
    }

    get canCreateTransaction(): boolean {
      return super.canCreateTransaction
        && (this.isTodaySelected || (!this.isTodaySelected && PermissionUtil.hasPermissionToEditAfterDayClosing()));
    }

    mounted() {

        super.mounted();

      this.updateOpenEntries();
      this.checkCashbookEmpty();
    }

    updated() {
      this.updateOpenEntries();
    }

    @Watch('activeCashbookTitle')
    cashbookChanged() {
      this.checkIfDayClosingIsActive();
      this.changeMonth();
    }

    containsProtocol(d: number): boolean {
      return this.$store.getters.reportEntries.filter(value => d === Number(value.DATUM.split('/')[2])).length > 0;
    }

    openReportForDay(d: number) {
      this.loadReportForDay = d;
      const date = this.$store.getters.reportEntries.filter(value => d === Number(value.DATUM.split('/')[2]))[0].DATUM;
      this.openReport(date
        , () => {
          this.loadReportForDay = -1;
        });
    }

    changeMonth() {
      this.$store.commit('SET_REPORT_ENTRIES', []);
      Webservice.getCashbookProtocols(() => {
      });
      super.changeMonth();
    }

    showTransactionDialog(): void {
      this.$store.commit('SET_FOOTER_ON_UI',
        { footerOnUI: false });
    }

    showDatePickerDialog(): void {
      if (!this.loadTableData) {
        this.$store.commit('SET_SHOW_DATEPICKER_DIALOG', true);
      }
    }

    private async showAttachment(item: CashbookEntriesResponseEntry) {
        this.attachment = null;
        let attachment:any = undefined;

        try{
            attachment = await this.$ports.cashbook.getCashbookEntryAttachment({
                cashbookId: this.$store.getters.selectedCashbook.NUM,
                entryId: item.LFDNUMMER,
                date: item.DATUM
            });
        }catch (e:any) {
            globalEventBus.$emit("showServiceError",e);
        }
        this.attachment = attachment || null;
        if (this.attachment?.isImage) {
            this.$nextTick(() => {
                let lightbox: Lightbox|Lightbox[] = this.$refs['lightbox-' + item.LFDNUMMER] as unknown as Lightbox|Lightbox[];
                if (Array.isArray(lightbox)) {
                    lightbox = lightbox[0];
                }
                if (lightbox) {
                    lightbox.show();
                } else {
                    console.log('error');
                }
            });
        } else if (this.attachment) {
            const link = document.createElement('a');
            link.href = this.attachment.url;
            link.download = 'Beleg';
            link.click();
        }

      // this.imageURL = '';
      // Webservice.getImageURLCashbookEntryImage(el.LFDNUMMER,
      //   el.DATUM, (value) => {
      //     this.imageURL = value;
      //   }, (response) => {
      //     // TODO: Handle Error
      //   });
    }

    deletable(item: CashbookEntriesResponseEntry) {
      const alreadyClosed: boolean = moment(this.$store.getters.cashbookBillingDate, REQUEST_DATEFORMAT)
        .isSameOrAfter(this.transactionDate, 'day');
      return item.BUCHUNGART !== 3 && (((item.PERSISTENZ === 1
        && (PermissionUtil.hasPermissionToDeleteCashbookEntry() && !alreadyClosed)))
        || ((item.PERSISTENZ === 2 || item.PERSISTENZ === 1) && ((PermissionUtil.hasPermissionToCancelCashbookEntry()
          || PermissionUtil.hasPermissionToEditAfterDayClosing()) && alreadyClosed)));
    }

    @Watch('$store.getters.selectedCashbook', {deep: true})
    @Watch('$store.getters.activeUserId')
    private async checkCashbookEmpty() {
        const cashbookId = this.$store.getters.selectedCashbook?.NUM;
        const userId = this.$ports.auth.getUserId();
        if (userId && cashbookId) {
            this.isEmptyCashbook = await Webservice.isEmptyCashbook(userId, cashbookId);
        }
    }

    async updateOpenEntries() {
      Webservice.getCashbookBillingState((val) => {
        if (this.$store.getters.cashbookBillingState.AbrechnungStatus !== val.AbrechnungStatus
          || this.$store.getters.cashbookBillingState.KassenberichtStatus !== val.KassenberichtStatus
          || this.$store.getters.cashbookBillingState.ZaehlprotokollStatus !== val.ZaehlprotokollStatus) {
          this.$store.commit('SET_CASHBOOK_BILLING_STATE', val);
        }
      });

      Webservice.countEntries(false, (amount: number) => {
        this.showTakePreStockStored = (amount === 0);
      });
      Webservice.countEntries(true, (amount: number) => {
        this.showDayEndClosingStored = (amount > 0);
      });
    }

    getDate(d: number): Date {
      return new Date(this.selectedYear, this.selectedMonth, d);
    }

    getDayStartCashBalance(d: number): number {
      const protocol = this.$store.getters.reportEntries.filter(value => d === Number(value.DATUM.split('/')[2]) && value.TYP === 2)[0];
      if (protocol) {
        return protocol.ISTBESTAND;
      }
      const entriesForMonth = this.paymentFilteredEntries;
      if (entriesForMonth.length > 0) {
        const list = entriesForMonth.filter(value => d >= Number(value.DATUM.split('/')[2]));
        if (list.length > 0) {
          return list[list.length - 1].SALDO;
        } else {
          const entry = entriesForMonth[0];
          return entry.ZAHLUNGSMITTEL !== 0 ? entry.SALDO : (entry.SALDO - (this.ingestion(entry) ? +entry.BRUTTO : -entry.BRUTTO));
        }
      }
      return 0;
    }

    getDayCashDelta(d: number): number {
      const protocol = this.$store.getters.reportEntries.filter(value => d === Number(value.DATUM.split('/')[2]) && value.TYP === 2)[0];
      if (protocol) {
        return protocol.DIFFERENZ;
      }
      return this.getDayStartCashBalance(d) - this.getDayStartCashBalance(d - 1);
    }

    ingestion(item: CashbookEntriesResponseEntry): boolean {
      const [type] = this.$store.getters.cashbookEntryTypes
        .filter(value => value.NUM === item.TYP);
      return isIngestion(type);
    }

    toggleCombobox() {
      const paymentCbx: any = this.$refs.paymentCbx;
      if (paymentCbx.isMenuActive) {
        (this.$refs.paymentCbx as any).isMenuActive = false;
      } else {
        (this.$refs.paymentCbx as any).isMenuActive = true;
        paymentCbx.focus();
      }
    }

    openTransactionDialogForEntry(mode: DialogMode, entry: CashbookEntriesResponseEntry) {
      this.$store.commit('SET_INGESTION', this.ingestion(entry));
      this.$store.commit('SET_TRANSACTION_DIALOG_MODE', mode);
      this.$store.commit('SET_CASHBOOK_ENTRY', entry);
      this.$store.commit('SET_SHOW_TRANSACTION_DIALOG', true);
    }

    transfer() {
      Webservice.insertTransferEntries(() => {
        Webservice.reloadCashbookEntries();
        Webservice.getCashbookBillingState((val) => this.$store.commit('SET_CASHBOOK_BILLING_STATE', val));
        Webservice.getCashbookBillingDate();
        this.updateOpenEntries();
      });
    }

    private getEntriesForDay(d: number): CashbookEntriesResponseEntry[] {
      return this.paymentFilteredEntries
        .filter(value => d == Number(value.DATUM.split('/')[2]))
        .sort((a, b) => a.LFDNUMMER - b.LFDNUMMER);
    }
  }
