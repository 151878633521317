import type { CashbookRepository } from '../adapter/CashbookRepository';
import type { CashbookEntryAttachment, CashbookEntryId } from '../models';

export class CashbookService {

    private readonly cashbookRepository: CashbookRepository;

    constructor(params: { cashbookRepository: CashbookRepository }) {
        this.cashbookRepository = params.cashbookRepository;
    }

    public async getCashbookEntryAttachment(cashbookEntryId: CashbookEntryId): Promise<CashbookEntryAttachment|undefined> {
        try {
            return await this.cashbookRepository.getCashbookEntryAttachment(cashbookEntryId);
        } catch (e) {
            throw e;
            console.error(e);
            return Promise.resolve(undefined);
        }
    }
}
